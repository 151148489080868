import React, { useEffect, useState } from "react";
import "./plans.scss";
import ResultHeader from "../../component/result/resultHeader";
// import ButtonInput from "../../atoms/button";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  Button,
  CardMedia,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import closex from "../../assets/images/x.png";
import { connect } from "react-redux";
import Loading from "../../component/Loader";
import SnackBar from "../../atoms/snackBar";
import { getProgramList, paymentCheckoutURL } from "../../redux/action/user";
import { useNavigate } from "react-router";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PlanSubscription = (props) => {
  const navigate = useNavigate();
  const [desc, setDesc] = useState("");
  const [descModal, setDescModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const [selectedPlan, setSelectedPlan] = useState(null); // State to track selected plan

  // const handleSelectPlan = (plan) => {
  //   if (selectedPlan?.ID === plan.ID) {
  //     setSelectedPlan(null); // If the same plan is clicked, collapse it
  //   } else {
  //     setSelectedPlan(plan); // Set the selected plan to show details on the right side
  //   }
  // };

  useEffect(() => {
    if (!localStorage.getItem("userKey")) {
      navigate("/");
    }
    props.getProgramList();
  }, []);

  useEffect(() => {
    if (props.user?.error.errormsg) {
      setErrorMessage(props.user?.error.errormsg);
    }
  }, [props.user?.error.errormsg]);

  useEffect(() => {
    if (errorMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMessage]);

  const isPlanPurchased = (plan) => {
    const isSubscriptionPurchased =
      props.user.subscription &&
      props.user.subscription.isSubscrption &&
      plan.isSubscription;

    const isProgramPurchased =
      props.user.programPurchased &&
      props.user.programPurchased?.some(
        (purchase) => purchase.programSubscriptionId === plan.ID
      );

    if (plan.isSubscription) {
      return isSubscriptionPurchased || isProgramPurchased;
    }
    return isProgramPurchased;
  };

  const handlePayment = async (ele) => {
    // const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const body = {
      ID: ele.ID,
    };
    await props.paymentCheckoutURL(body);
  };

  const handleSeeMore = (description) => {
    setDesc(description);
    setDescModal(true);
  };

  const formatDuration = (duration, interval) => {
    const formattedInterval = duration > 1 ? `${interval}s` : interval;
    return `${duration} ${formattedInterval}`;
  };

  return (
    <>
      <ResultHeader />
      {props.user.isLoading ? (
        <Loading />
      ) : (
        <div className="plans">
          <Grid container spacing={2}>
            {props.user.programList && props.user.programList.length > 0 ? (
              props.user.programList.map((ele, index) => (
                <Grid item xs={12} sm={6} md={4} key={ele.ID}>
                  <div className="plan-block">
                    <div className="plan-title">
                      <Typography
                        variant="h5"
                        className="white-color avenir-bold"
                      >
                        {ele.isSubscription ? "SUBSCRIPTION" : "PROGRAM"}
                      </Typography>
                    </div>
                    <div
                      className="plan-detail"
                      style={{ textAlign: "center" }}
                    >
                      <Typography variant="h6" className="plan-head">
                        {ele.name}
                      </Typography>
                      <div className="ImgWrap">
                        <CardMedia
                          component="img"
                          alt={ele.name}
                          // height="140"
                          image={ele.imageUrl}
                          title={ele.name}
                        />
                      </div>

                      <Typography className="plan-desc">
                        {ele.description && ele.description.length > 120 ? (
                          <>
                            {ele.description.slice(0, 120)}...
                            <span
                              className="see-more"
                              onClick={() => handleSeeMore(ele.description)}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              See more
                            </span>
                          </>
                        ) : (
                          ele.description
                        )}
                      </Typography>
                    </div>
                    <div className="BuyNowOption">
                      <div className="TextWrap">
                        <Typography>
                          Validity:{" "}
                          {ele.duration &&
                            formatDuration(ele.duration, ele.interval)}
                        </Typography>
                        <Typography>
                          Price:{" "}
                          {`$${ele.amount} ${
                            ele.currency && ele.currency.toUpperCase()
                          }`}
                        </Typography>
                      </div>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handlePayment(ele)}
                        className="buy-now-button"
                        disabled={isPlanPurchased(ele)}
                        sx={{
                          backgroundColor: isPlanPurchased(ele)
                            ? "grey !important"
                            : "#e29c2e !important",
                          pointerEvents: isPlanPurchased(ele) ? "none" : "auto",
                        }}
                      >
                        {isPlanPurchased(ele)
                          ? ele.isSubscription
                            ? "Active"
                            : "Purchased"
                          : "Buy Now"}
                      </Button>
                    </div>
                  </div>
                </Grid>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="60vh"
                sx={{ textAlign: "center", color: "white", flex: "auto" }} // MUI sx prop for styling
              >
                <Typography variant="h6">No Plan is available</Typography>
              </Box>
            )}
          </Grid>
          {/* {data &&
            data.map((ele, index) => (
              <div className="plan-block" key={index}>
                <div className="plan-title">
                  <h2 className="white-color avenir-bold">
                    {ele.isSubscription ? "SUBSCRIPTION" : "PLAN"}
                  </h2>
                </div>
                <div className="plan-detail" style={{ textAlign: "center" }}>
                  <Typography className="plan-head">{ele.name}</Typography>
                  <img src={ele.imageUrl}></img>
                  <Typography className="plan-desc">
                    {ele.description && ele.description.length > 120 ? (
                      <>
                        {ele.description.slice(0, 120)}...
                        <span
                          className="see-more"
                          onClick={() => handleSeeMore(ele.description)}
                        >
                          See more
                        </span>
                      </>
                    ) : (
                      ele.description
                    )}
                  </Typography>
                  <Typography>Validity- {ele.duration}</Typography>
                  <Typography>Price: {ele.amount}</Typography>
                  <ButtonInput
                    class="buy-now-button"
                    text={`Buy Now`}
                    onSubmit={() => handlePayment()}
                  />
                </div>
              </div>
            ))} */}
        </div>

        // <Grid container spacing={2}>
        //   {/* Left Side - Accordion with Plan Titles */}
        //   <Grid item xs={12} sm={4} md={3}>
        //     <div className="plans-left">
        //       {props.user.programList && props.user.programList.length > 0 ? (
        //         props.user.programList.map((ele, index) => (
        //           <Accordion
        //             key={ele.ID}
        //             expanded={selectedPlan?.ID === ele.ID} // Expand if this plan is selected
        //             onChange={() => handleSelectPlan(ele)} // Toggle accordion when clicked
        //           >
        //             <AccordionSummary
        //               expandIcon={<ExpandMoreIcon />}
        //               aria-controls={`panel${ele.ID}-content`}
        //               id={`panel${ele.ID}-header`}
        //             >
        //               <Typography variant="h6">
        //                 {ele.isSubscription ? "SUBSCRIPTION" : "PROGRAM"} -{" "}
        //                 {ele.name}
        //               </Typography>
        //             </AccordionSummary>
        //             <AccordionDetails>
        //               <Typography variant="body2">
        //                 {ele.description && ele.description.length > 120 ? (
        //                   <>
        //                     {ele.description.slice(0, 120)}...
        //                     <span
        //                       className="see-more"
        //                       onClick={() => handleSeeMore(ele.description)}
        //                       style={{ cursor: "pointer", color: "blue" }}
        //                     >
        //                       See more
        //                     </span>
        //                   </>
        //                 ) : (
        //                   ele.description
        //                 )}
        //               </Typography>
        //             </AccordionDetails>
        //           </Accordion>
        //         ))
        //       ) : (
        //         <Box
        //           display="flex"
        //           justifyContent="center"
        //           alignItems="center"
        //           height="60vh"
        //           sx={{ textAlign: "center", color: "white", flex: "auto" }} // MUI sx prop for styling
        //         >
        //           <Typography variant="h6">No Plan is available</Typography>
        //         </Box>
        //       )}
        //     </div>
        //   </Grid>

        //   {/* Right Side - Full Plan Details */}
        //   <Grid item xs={12} sm={8} md={9}>
        //     <div className="plan-details-right">
        //       {selectedPlan ? (
        //         <div className="plan-block">
        //           <div className="plan-title">
        //             <Typography
        //               variant="h5"
        //               className="white-color avenir-bold"
        //             >
        //               {selectedPlan.isSubscription ? "SUBSCRIPTION" : "PROGRAM"}
        //             </Typography>
        //           </div>
        //           <div className="plan-detail" style={{ textAlign: "center" }}>
        //             <Typography variant="h6" className="plan-head">
        //               {selectedPlan.name}
        //             </Typography>
        //             <div className="ImgWrap">
        //               <CardMedia
        //                 component="img"
        //                 alt={selectedPlan.name}
        //                 image={selectedPlan.imageUrl}
        //                 title={selectedPlan.name}
        //                 sx={{ height: "50%" }}
        //               />
        //             </div>
        //             <Typography className="plan-desc">
        //               {selectedPlan.description}
        //             </Typography>
        //           </div>
        //           <div className="BuyNowOption">
        //             <div className="TextWrap">
        //               <Typography>
        //                 Validity:{" "}
        //                 {selectedPlan.duration &&
        //                   formatDuration(
        //                     selectedPlan.duration,
        //                     selectedPlan.interval
        //                   )}
        //               </Typography>
        //               <Typography>
        //                 Price:{" "}
        //                 {`${selectedPlan.amount} ${
        //                   selectedPlan.currency &&
        //                   selectedPlan.currency.toUpperCase()
        //                 }`}
        //               </Typography>
        //             </div>
        //             <Button
        //               variant="contained"
        //               color="primary"
        //               onClick={() => handlePayment(selectedPlan)}
        //               className="buy-now-button"
        //             >
        //               Buy Now
        //             </Button>
        //           </div>
        //         </div>
        //       ) : (
        //         <Box
        //           display="flex"
        //           justifyContent="center"
        //           alignItems="center"
        //           height="60vh"
        //           sx={{ textAlign: "center", color: "white", flex: "auto" }}
        //         >
        //           <Typography variant="h6">
        //             Select a plan to see details
        //           </Typography>
        //         </Box>
        //       )}
        //     </div>
        //   </Grid>
        // </Grid>
      )}
      <Modal open={descModal}>
        <Box sx={style} className="modal w-95">
          <div className="close-pop-wrap">
            <img
              src={closex}
              alt={closex}
              onClick={() => setDescModal(false)}
            ></img>
          </div>
          <div className="desc-wrap">
            <p>{desc}</p>
          </div>
        </Box>
      </Modal>
      <SnackBar
        open={openSnackBar}
        message={errorMessage}
        handleCloseSnackBar={() => {
          setOpenSnackBar(false);
          setErrorMessage("");
        }}
      />
    </>
  );
};

PlanSubscription.propTypes = {};
PlanSubscription.defaultProps = {};

const actionCreators = {
  paymentCheckoutURL,
  getProgramList,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(PlanSubscription);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

// const data = [
//   // {
//   //   type: "Plan",
//   //   heading: "Vibrant Life Method",
//   //   desc: "90 days of coaching to support your detoxification and movement to achieve greater clarity and strength.",
//   //   validity: "90 days",
//   //   price: "$599.00",
//   //   id: 1,
//   // },
//   {
//     ID: 1,
//     name: "HEALTH RESET CHALLENGE",
//     description:
//       "At the beginning of this reset, you will be provided with a daily checklist outlining all of the behaviors you can incorporate into your routine to maximize your health and vitality through a circadian and mitochondrially-oriented approach. By the end of ",
//     amount: 176,
//     imageUrl:
//       "https://japa.health/wp-content/uploads/2024/03/16330e6e-432b-48ae-96f0-51b5d50d1b22_Page_12_Image_0001-1024x576.jpg",
//     isSubscription: false,
//     status: "1",
//     duration: 21,
//     createdAt: "2024-10-30T13:10:10.000Z",
//     updatedAt: "2024-10-30T13:10:10.000Z",
//     updatedBy: 0,
//   },
//   {
//     ID: 2,
//     name: "Basic 1 Month Subscription",
//     description: "Monthly access to all programs",
//     amount: 297,
//     imageUrl: "https://japa.health/wp-content/uploads/2022/09/Screenshot-2.png",
//     isSubscription: true,
//     status: "1",
//     duration: 30,
//     createdAt: "2024-10-30T13:10:10.000Z",
//     updatedAt: "2024-10-30T13:10:10.000Z",
//     updatedBy: 0,
//   },
//   {
//     ID: 3,
//     name: "VIBRANT LIFE METHOD 90 Day Transformation",
//     description:
//       "By the end of the 90 day program, you will fully understand the basis for all of the recommendations provided, so that you are empowered with not only the action items but the underlying information and thought processes behind them.",
//     amount: 599,
//     imageUrl:
//       "https://japa.health/wp-content/uploads/2024/03/16330e6e-432b-48ae-96f0-51b5d50d1b22_Page_13_Image_0001-1024x576.jpg",
//     isSubscription: false,
//     status: "1",
//     duration: 90,
//     createdAt: "2024-10-30T13:10:10.000Z",
//     updatedAt: "2024-10-30T13:10:10.000Z",
//     updatedBy: 0,
//   },
// ];
